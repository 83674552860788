import React, { Component, createRef } from "react";
import { Typography, Grid } from "@material-ui/core";
import { AuthContext } from "../../store/authProvider";

export default class DownloadFileComponent extends Component {
    static contextType = AuthContext;

    linkRef = createRef();

    onDownloadClick = async () => {
        if (this.linkRef.current.href) { return }
        let blob = await this.fetchDownload();
        const href = window.URL.createObjectURL(blob);
        this.linkRef.current.download = this.props.fileName;
        this.linkRef.current.href = href;
        this.linkRef.current.click();
    }

    fetchDownload = async () => {
        const result = await fetch(
            `${this.props.apiServer}${this.props.porzioApiPath}/${this.context.user.tenantId}?blobName=${this.props.blobName}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.context.authToken}`,
                },
            }
        );
        const blob = await result.blob();
        return blob;
    };

    render() {
        return (
            <Typography b={0} style={{
                fontSize: 16, fontWeight: 400, lineHeight: 1.9,
                overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",
                cursor: "pointer",
            }}><a role='button'
                style={{ textDecoration: "underline" }} onClick={this.onDownloadClick}
                ref={this.linkRef}>{this.props.fileName}</a></Typography>
        );
    }
}
