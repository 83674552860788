import React from "react";
import { combineLatest, } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    AppBar,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    Toolbar,
    Typography,
    FormControl,
    Divider,
} from "@material-ui/core";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { MatIconService } from "../../../shared/services/theme/matIconService"
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, API_ENDPOINT, } from "../../../shared/types/enums";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../shared/services/layoutService";
import ApiService from "../../../shared/services/apiService";
import ToastService from "../../../shared/services/toastService";
import UploadFileService from "./uploadFileService";

class UploadFileComponent extends React.Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            fetchResult: ResultStatus.NOT_LOADED,
        }
    }

    componentWillUnmount() {
        this.oSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.setState({ fetchResult: ResultStatus.LOADING });
        /**
         * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
         * this operator is very handy as we don't have to independently wait for each result
         */
        this.oSubscriptions.add(
            combineLatest([
                UploadFileService.getSourceSystemsAsOBS(this.context.user.tenantId),
            ]).subscribe(([_sourceData,]) => {
                this.setState(
                    {
                        sourceData: _sourceData.filter(o => o.active === true && o.sourceTypeId != 3),
                    },
                    () => {
                        // change the state after all the above are assigned
                        this.setState({ fetchResult: ResultStatus.LOADED });
                    }
                );
            }
            )
        );
    }

    getInitialValues() {
        return {
            sourceSystemId: null,
            selectedFile: null,
        };
    }

    validationSchema = Yup.object().shape({});
    getValidationSchema() {
        this.validationSchema = Yup.object().shape({
            sourceSystemId: Yup.number().required("Required").typeError('Must select a Source System ID'),
            selectedFile: Yup.mixed().required("Must select an Upload File")
                // .test(
                //     "fileFormat",
                //     "File rejected. Please submit a .csv file.",
                //     value => value && ["text/csv"].includes(value.type)
                // )
                .test(
                    "fileFormat",
                    "File rejected. Please submit a .csv file.",
                    value => value && value.name.toUpperCase().endsWith(".CSV")
                )
        });
        return this.validationSchema;
    }

    handleClose = () => {
        this.props.handleUploadDialogClose();
    };

    handleSubmit = async (_formikProps) => {
        console.log("_formikProps", _formikProps);
        if (!_formikProps.isSubmitting && _formikProps.isValid) {
            await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
                .then((x) => {

                    // 1) set the status
                    this.setState({ fetchResult: ResultStatus.SAVING, });

                    // 2) extract the formik values into a FormData object
                    const formData = new FormData();
                    formData.append('tenantId', this.context.user.tenantId);
                    formData.append('sourceId', _formikProps.values.sourceSystemId);
                    formData.append('userId', this.context.user.userId);
                    formData.append('userName', this.context.user.username);
                    formData.append('efile', _formikProps.values.selectedFile);

                    // 3) determine the action and assign the appropriate props
                    const actionVerb = "POST";
                    const targetUrl = `/File/Upload`;
                    const headers = {
                        Authorization: `Bearer ${this.context.authToken}`
                    };

                    // 4) save to Api and subscribe for the result
                    ApiService.setOBS(
                        actionVerb,
                        API_ENDPOINT.CORE,
                        targetUrl,
                        formData,
                        headers
                    ).subscribe(
                        (successResult) => {
                            if (successResult) {
                                this.setState({ fetchResult: ResultStatus.SUCCESS });
                                ToastService.showSuccess("File submitted successfully. System will begin to process file now.");
                                this.props.handleUploadDialogClose();
                            } else {
                                ToastService.showError("An Error occured while Uploading the File");
                                console.error("Error: falsey successResult while saving File Upload", successResult);
                                this.setState({ fetchResult: ResultStatus.ERROR });
                            }
                        },
                        (errorResult) => {
                            ToastService.showError("An Error occured while Uploading the File");
                            console.error("Error while saving File Upload", errorResult);
                            this.setState({ fetchResult: ResultStatus.ERROR });
                        }
                    );
                })
                .catch((erroObj) => {
                    erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
                });
        }
    }

    render() {
        const { classes } = this.props;

        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
            case ResultStatus.SAVING:
                return (
                    <PageLoadingComponent
                        small
                        classes={classes}
                        label="Loading Upload File"
                    />
                );
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (      
                    <Dialog open={this.props.openUploadDialog || false} scroll={true ? "paper" : "body"} maxWidth="xs" >      
                        <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                            {(fProps) => (
                                <form>
                                    <DialogTitle disableTypography id="dialogTitle">
                                        <AppBar position="static">
                                            <Toolbar variant="dense">
                                                <Typography variant="h6" className={classes.root}>
                                                    Upload File
                                                </Typography>
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => {
                                                        this.handleSubmit(fProps);
                                                    }}
                                                >
                                                    {MatIconService.OK}
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={this.handleClose}
                                                >
                                                    {MatIconService.CANCEL}
                                                </IconButton>
                                            </Toolbar>
                                        </AppBar>
                                    </DialogTitle>
                                    <DialogContent style={{ paddingTop: "25px", paddingLeft: "25px", height: "35vh", overflowX:"hidden"}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {
                                                    LayoutService.getSelectControl(this.state.isReadOnly,
                                                        classes,
                                                        fProps,
                                                        "sourceSystemId",
                                                        "Source System",
                                                        this.state.sourceData,
                                                        "systemId",
                                                        "sourceLabel",
                                                        "90%"
                                                    )
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    style={{ minWidth: "90%" }}
                                                    className={classes.dialogControl}
                                                    error={
                                                        fProps.errors["selectedFile"] !== undefined &&
                                                        fProps.errors["selectedFile"] !== null
                                                    }
                                                >
                                                    <FormHelperText>Source Container</FormHelperText>
                                                    <input id="file" name="file" type="file"
                                                        className={classes.input}
                                                        accept=".csv"
                                                        onChange={(event) => {
                                                            fProps.setFieldValue("selectedFile", event.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    <Divider />
                                                    <FormHelperText>{fProps.errors["selectedFile"]}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </form>
                            )}
                        </Formik>                   
                    </Dialog>            
                );
            case ResultStatus.ERROR:
            default:
                return (
                    <PageErrorComponent
                        small
                        label="Error Loading Upload File"
                        classes={classes}
                        onRetry={() => {
                            this.fetchData(true);
                        }}
                    />
                );
        }
    }
}

/** HOC */
export default LayoutService.getHocComponenet(UploadFileComponent);