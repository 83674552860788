import ApiService from "../../shared/services/apiService";
import Cache from "../../shared/store/cache";
import { GST_API } from "../../shared/types/enums";
import { from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MatIconService } from "../../shared/services/theme/matIconService";
import ToastService from "../../shared/services/toastService";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
export default class transactionCenterService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    let sortqry = " ";
    let filterQry = " ";
    let pageNo = 1;
    let pageSize = 5000;
    let url = `/Transactions/${clientAuthContext.user.tenantId}/${clientAuthContext.user.userId}/${sortqry}/${filterQry}/${pageNo}/${pageSize}`;
    return ApiService.fetchHTTP_API(GST_API.Core, url);
  }

  static fetchTransactionsFromApiObs(fileId, column, context) {
    this.CACHE.CLEAR();

    let columns = { Tran: "transactionCount", Error: "error", Warn: "warning", Unmatch: "unmatched", NonLicensed: "nonLicensed" };
    let healthIndicatorId = "";
    switch (column) {
      case columns.Tran:
        healthIndicatorId = "";
        break;
      case columns.Error:
        healthIndicatorId = 1;
        break;
      case columns.Warn:
        healthIndicatorId = 2;
        break;
      case columns.Unmatch:
        healthIndicatorId = 3;
        break;
      case columns.NonLicensed:
        healthIndicatorId = 5;
        break;
      default:
        healthIndicatorId = "";
        break;
    }

    const roleObj = JSON.stringify({
      tenantId: context.user.tenantId,
      fileId: parseInt(fileId),
      userId: context.user.userId,
      userType: context.user.userTypeId,
      healthIndicatorId: parseInt(healthIndicatorId),
      page: 1,
      pageSize: 5000

    });

    const result = from(fetch(`${process.env.REACT_APP_CORE_HTTP}/Transactions/GetExpandedViewDetails`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: roleObj,
    })).pipe(switchMap((response) => response.json()));
    return result;
  }


  static getRows() {
    return [];
  }

  static getActionMenuParams = (_componentThisRef) => {
    return {
      menuIcon: MatIconService.MORE_VERT_16,
      menuMaxHeight: 60,

      menuOptions: [
        {
          text: "Add/Edit a File Comment", icon: MatIconService.FILE_COMMENT, onClickCallback: (_cellRef) => {
            _componentThisRef.handleAddEditCommentFile(_cellRef);
          }
        },
        {
          text: "Delete File", icon: MatIconService.DELETE, onClickCallback: (_cellRef) => {
            _componentThisRef.handleDeleteFile(_cellRef);
          }
        },
        {
          text: "Petite Validation", icon: MatIconService.RERUN_VALIDATION, onClickCallback: (_cellRef) => {
            _componentThisRef.handleRerunValidationConfirm(_cellRef);
          }
        },
        {
          text: "Re-run File Validation", icon: MatIconService.REVALIDATE_QUEUE, onClickCallback: (_cellRef) => {
            _componentThisRef.handleAddToRerunFileValidationQueueConfirm(_cellRef);
          }
        },
        // {
        //   text: "Re-run Matching", icon: MatIconService.RERUN_MATCHING, onClickCallback: (_cellRef) => {
        //     ToastService.showInfo("Under Construction");
        //   }
        // },
        // {
        //   text: "Run Health Reports", icon: MatIconService.RUN_REPORTS, onClickCallback: (_cellRef) => {
        //     ToastService.showInfo("Under Construction");
        //   }
        // },
      ]
    }
  }

  static getColumnDefs(_componentThisRef) {
    return [
      AgGridColumnExt.GET(false, null, null, "").fixedWidth(48).headerClass("center-text").cellRenderer("actionCellRendererComponent", this.getActionMenuParams(_componentThisRef)).SET(),
      AgGridColumnExt.GET(false, "text", "receivedDate", "Processed Date").fixedWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, true, "sourceName", "Source").flexWeight(1).minWidth(144).headerClass("center-text").pivot().groupDefaultExpanded(4).SET(),
      AgGridColumnExt.GET(false, true, "fileName", "File Name").flexWeight(6).minWidth(162).filterOptions("agTextColumnFilter", "contains").cellRenderer("commentIconTextRenderer",
        {
          getIcon: (_cellRef) => {
            if (_cellRef.props.data && _cellRef.props.data.hasComments) {
              return MatIconService.FILE_COMMENT_ON;
            } else {
              return null; //MatIconService.FILE_COMMENT_OFF;
            }
          }
        }
      ).SET(),
      AgGridColumnExt.GET(false, "text", "transactionRange", "Transaction Date Range").flexWeight(4).minWidth(128).maxWidth(176).headerClass("center-text").tooltip().SET(),
      AgGridColumnExt.GET(false, "number", "transactionCount", "Transaction Count").fixedWidth(136).headerClass("center-text").centerAlignText().enableValue().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "error", "Errors").fixedWidth(96).headerClass("center-text").enableValue().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "warning", "Warnings").fixedWidth(112).headerClass("center-text").enableValue().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "unmatched", "Unmatched").fixedWidth(136).headerClass("center-text").enableValue().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "nonLicensed", "NonLicensed").fixedWidth(136).headerClass("center-text").enableValue().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "eligible", "Eligible").fixedWidth(112).headerClass("center-text").enableValue().cellRenderer("childMessageRendererComponent").SET(),
    ];

  }

  static getExpandedViewColumnDefs() {
    return [
      {
        headerName: "Health Indicator",
        field: "healthindicator", headerTooltip: "Health Indicator",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true, enableValue: true, cellRenderer: "childMessageRendererComponent",
        width: 225,
      },
      {
        headerName: "Rule Violations",
        field: "ruleviolations", headerTooltip: "Rule Violations",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
      {
        headerName: "Company Transaction Id",
        field: "companytransactionid",
        sortable: true,
        filter: "text", headerTooltip: "Company Transaction Id",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
      { 
        headerName: "Total Amount",
        field: "totalamount",
        sortable: true,
        filter: "text", headerTooltip: "Total Amount",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
      {
        headerName: "Base Currency",
        field: "currency",
        sortable: true,
        filter: "text", headerTooltip: "Base Currency",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
      {
        headerName: "Last Name",
        field: "lastname",
        sortable: true,
        filter: "text", headerTooltip: "Last Name",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
      {
        headerName: "First Name",
        field: "firstname",
        sortable: true,
        filter: "text", headerTooltip: "First Name",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
      {
        headerName: "Organization Name",
        field: "organizationname",
        sortable: true,
        filter: "text", headerTooltip: "Organization Name",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
      {
        headerName: "Country",
        field: "country",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
    ]
  }
}
