import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import { AgGridService } from "../../shared/services/ag-grid/agGridService";
import ApiService from "../../shared/services/apiService";
import { MatIconService } from "../../shared/services/theme/matIconService";
import ToastService from "../../shared/services/toastService";
import Cache from "../../shared/store/cache";
import { ALIGNMENT, GST_API } from "../../shared/types/enums";


export default class ProfileCenterService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static parentRef = null;
  static INIT = (_parentRef) => {
    ProfileCenterService.parentRef = _parentRef;
  }

  static profileDetailParams = null;
  static showProfileDetailDialogIfExists = () => {
    if (ProfileCenterService.profileDetailParams) {
      // for some reason not showing imediately, so shwoing after a timeout
      setTimeout(() => { this.parentRef.setState({ showProfileDetailDialog: true }) }, 100);
    }
  }

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();

    return ApiService.fetchHTTP_API(GST_API.Core, "/Profiles/FetchProfileCenterDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenantId: clientAuthContext.user.tenantId,
        pageNumber: 1,
        pageSize: 5000,
        whereClause: "",
        sortClause: "",
        permissions: [
          {
            permissionId: 0,
            permissionName: "string",
            parentId: 0,
            fieldId: 0,
            fieldLovId: 0,
            isActive: true,
            canCreate: true,
            canEdit: true,
            canDelete: true,
            canViewDelete: true,
            canView: true,
            canConfigure: true,
          },
        ],
        userType: 0,
      }),
    });
  }

  static getRows() {
    return [];
  }

  static getActionMenuParams = (_componentThisRef) => {
    return {
      menuIcon: MatIconService.MORE_VERT_16,
      menuMaxHeight: 60,

      menuOptions: [
        {
          text: "Add/Edit a File Comment", icon: MatIconService.FILE_COMMENT, onClickCallback: (_cellRef) => {
            _componentThisRef.handleAddEditCommentFile(_cellRef);
          }
        },
        {
          text: "Re-run File Validation", icon: MatIconService.RERUN_VALIDATION, onClickCallback: (_cellRef) => {
            _componentThisRef.handleRerunValidationConfirm(_cellRef);
          }
        },
        // {
        //   text: "Re-run Matching", icon: MatIconService.RERUN_MATCHING, onClickCallback: (_cellRef) => {
        //     ToastService.showInfo("Under Construction");
        //   }
        // },
        // {
        //   text: "Run Health Reports", icon: MatIconService.RUN_REPORTS, onClickCallback: (_cellRef) => {
        //     ToastService.showInfo("Under Construction");
        //   }
        // },
      ]
    }
  }
  static getColumnDefs(_componentThisRef) {
    return [
      AgGridColumnExt.GET(false, null, null, "").fixedWidth(48).headerClass("center-text").cellRenderer("actionCellRendererComponent", this.getActionMenuParams(_componentThisRef)).SET(),
      AgGridColumnExt.GET(false, "text", "receivedDate", "Processed Date").fixedWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "set", "sourceName", "Source").flexWeight(1).minWidth(112).headerClass("center-text").pivot().groupDefaultExpanded(3).SET(),
      AgGridColumnExt.GET(false, "text", "fileName", "File Name").flexWeight(6).minWidth(162).tooltip().cellRenderer("agGridAppendCommentIconCellRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "profileCount", "Profile Count").fixedWidth(104).headerClass("center-text").centerAlignText().cellRenderer("profileCountCellRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "errorCount", "Errors").fixedWidth(96).headerClass("center-text").cellRenderer("profileErrorCellRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "warningCount", "Warnings").fixedWidth(112).headerClass("center-text").cellRenderer("profileWarningCellRendererComponent").SET(),
      AgGridColumnExt.GET(false, "number", "unmatchedCount", "Unmatched").fixedWidth(136).headerClass("center-text").cellRenderer("profileUnmatchedCellRendererComponent").SET(),
    ];
  }
}