import React from 'react';
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppBar, Box, Dialog, DialogContent, InputLabel, Select, MenuItem, FormControl, FormHelperText, DialogContentText, DialogTitle, FormGroup, Grid, TextField, Toolbar, Typography, Chip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LookupService from "../../../shared/services/lookupService";
import LayoutService from "../../../shared/services/layoutService";
import ApiService from "../../../shared/services/apiService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import ToastService from "../../../shared/services/toastService";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { ResultStatus, API_ENDPOINT, CrudAction, ReportCategory } from "../../../shared/types/enums";
import ReportSaveService from "./reportSaveService";
import MatThemeService from "../../../shared/services/theme/matThemeService";
import IzendaAuthService from '../../../private/reporting/services/izendaAuthService';

//Filter names coming from Izenda : any change to this list needs to also be made to sourceFieldNameMap
const IzendaFilters = {
    START_DATE: '@startdate',
    END_DATE: '@enddate',
    TRANSACTION_START_DATE: '@transactionstartdate',
    TRANSACTION_END_DATE: '@transactionenddate',
    RECEIVED_START_DATE: '@received_startdate',
    RECEIVED_END_DATE: '@received_enddate',
    IZENDA_DISPLAY_NAME: "@izendadisplayname",
    AFFILIATION_NAME: 'affliationname',
    FINAL_DISPOSITION: 'finaldisposition',
    PRODUCT: 'product',
    GENERIC_COUNTRY_NAME: 'genericcountryname',
    UNITED_KINGDOM_COUNTRY: 'unitedkingdomcountry',
    CURRENCY: '@currency',
    SUBMITTING_ENTITY: '@submittingentity',
    CONSOLIDATED_REPORT: '@consolidatedreport',
    PRODUCTS: 'products',
    AFFILIATED_COMPANIES: 'affiliated companies',
    INCLUDED_ENTITIES: '@includedentities',
    PRODUCTS2: '@products',
    FEDERAL_PREEMPTION: '@federalpreemption',
    STATE: '@state',
    RESEARCH_RELATED: '@researchreleated',
    SOURCE: '@source',
    COMPANY_AFFILIATE: '@companyaffiliate',
    VENDOR: '@vendor',
    INCLUDED_IN_CONTRACT: '@includedincontract',
    FILENAME: '@filename',
};

class ReportSaveComponent extends React.Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();
    US_JURISDICTION = "UNITED STATES";
    sourceFieldNameMap = new Map();
    showDefaultAllValueFields = [IzendaFilters.AFFILIATION_NAME, IzendaFilters.PRODUCT, IzendaFilters.GENERIC_COUNTRY_NAME,
    IzendaFilters.PRODUCTS, IzendaFilters.AFFILIATED_COMPANIES, IzendaFilters.INCLUDED_ENTITIES, IzendaFilters.PRODUCTS2, IzendaFilters.FINAL_DISPOSITION,
    IzendaFilters.SOURCE, IzendaFilters.COMPANY_AFFILIATE, IzendaFilters.VENDOR, IzendaFilters.INCLUDED_IN_CONTRACT, IzendaFilters.FILENAME];

    constructor(props) {
        super(props);

        this.izAuthService = new IzendaAuthService();
        this.state = {
            fetchResult: ResultStatus.LOADING,
            izendaLanguageData: [],
            saveToReportLibraryResults: null,
            filterFields: [],
            izendaCurrentTenantId: null,
            errorMessage: "Error occurred while saving the Report"
        };
    }

    componentDidMount() {
        this.doStuff();
    }

    doStuff = async () => {
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
        /* Register Porzio User. After a Porzio user logs into the SPA, they will need to have all their Porzio Data translated
          into Izenda data. Will call authapi/Account/RegisterPorzioUser */
        const result = await this.izAuthService.registerUser();
        if (!result.success) {
            ToastService.showError("User Registration Failed.");
            this.setState({
                fetchResult: ResultStatus.ERROR, errorMessage: result.message, data: []
            });
        } else {
            this.initFilterSourcNameMap();
            this.fetchIzendaCurrentTenantId();
            this.fetchFiltersFields();
        }
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    //TODO : Need a better approach ; 
    //Maps filter names coming from procedure to source field names provided by Izenda's API
    initFilterSourcNameMap = () => {
        this.sourceFieldNameMap.set("@startdate", "@startdate");
        this.sourceFieldNameMap.set("@enddate", "@enddate");
        this.sourceFieldNameMap.set("@transactionstartdate", "@transactionstartdate");
        this.sourceFieldNameMap.set("@transactionenddate", "@transactionenddate");
        this.sourceFieldNameMap.set("@received_startdate", "@received_startdate");
        this.sourceFieldNameMap.set("@received_enddate", "@received_enddate");
        this.sourceFieldNameMap.set("@izendadisplayname", "@izendadisplayname"); //HIdden filter
        this.sourceFieldNameMap.set("affliationname", "AffiliationName");
        this.sourceFieldNameMap.set("finaldisposition", "FinalDisposition");
        this.sourceFieldNameMap.set("product", "Product");
        this.sourceFieldNameMap.set("genericcountryname", "GenericCountryName");
        this.sourceFieldNameMap.set("unitedkingdomcountry", "UnitedKingdomCountry");
        this.sourceFieldNameMap.set("@currency", "Currency");
        this.sourceFieldNameMap.set("@submittingentity", "SubmittingEntity");
        this.sourceFieldNameMap.set("@consolidatedreport", "ConsolidatedReport");
        this.sourceFieldNameMap.set("products", "Products");
        this.sourceFieldNameMap.set("affiliated companies", "AffiliatedCompanies");
        this.sourceFieldNameMap.set("@includedentities", "IncludedEntities");
        this.sourceFieldNameMap.set("@products", "Products2");
        this.sourceFieldNameMap.set("@federalpreemption", "FederalPreemption");
        this.sourceFieldNameMap.set("@state", "State");
        this.sourceFieldNameMap.set("@filename", "FileName");
        this.sourceFieldNameMap.set("@includedincontract", "IncludedInContract");
        this.sourceFieldNameMap.set("@source", "Source");
        this.sourceFieldNameMap.set("@vendor", "Vendor");
        this.sourceFieldNameMap.set("@companyaffiliate", "CompanyAffiliate");
        this.sourceFieldNameMap.set("@researchreleated", "ResearchRelated");
    }

    fetchIzendaCurrentTenantId = () => {
        const headers = {
            access_token: `${this.context.user.sessionToken}`,
            "Content-Type": "application/json",
        };
        let url = `${process.env.REACT_APP_IZENDA_CORE_HTTP}/api/tenant/allTenants`;
        fetch(url, {
            method: "GET",
            headers: headers
        }).then((result) => result.json())
            .then((jsonResult) => {
                let currentTenantId = DataService.hasElements(jsonResult) && jsonResult.some(t => t.tenantID == this.context.user.tenantId) ?
                    jsonResult.filter(t => t.tenantID == this.context.user.tenantId)[0]['id']
                    : null;
                this.setState({
                    izendaCurrentTenantId: currentTenantId
                });
            }).catch((err) => {
                console.log(err);
                this.setState({ fetchResult: ResultStatus.ERROR });
            });
    }

    fetchFiltersFields = () => {
        let postObj = { reportKey: { key: this.props.modalAgNode.izendaReportId } };
        const headers = { access_token: `${this.context.authToken}` };

        this.apiSubscriptions.add(ReportSaveService.fetchFiltersByReport(JSON.stringify(postObj), headers).subscribe(
            (result) => {
                //Since there is inconsistency in json format returned for 
                //'No permission' error message
                if (result.messages && !result.success) {
                    this.setState({
                        fetchResult: ResultStatus.ERROR,
                        errorMessage: result.messages && result.messages[0] ?
                            result.messages[0].messages : "Error occurred while fetching Report Filters"
                    });
                } else if (result.report && result.report.reportFilter.filterFields) {
                    let modifiedFilterFields = result.report.reportFilter.filterFields.map(function (obj) {
                        obj.sourceFieldName = obj.sourceFieldName.toLowerCase();
                        return obj;
                    });
                    this.setState({
                        filterFields: modifiedFilterFields
                    }, () => {
                        this.fetchData();
                    })
                }
            },
            (error) => {
                console.log("Error:", error);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        ));
    }

    fetchData = async (_ignoreCache = true) => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });


        this.apiSubscriptions.add(
            combineLatest([
                ReportSaveService.fetchLanguagesByParentIzendaAsOBS(this.context.user.tenantId, this.props.modalAgNode.izendaDisplayName),
                ReportSaveService.fetchReportCompanionDetails(this.context.user.tenantId, this.props.modalAgNode.izendaDisplayName),
                ReportSaveService.fetchReportSaveFileTypes(),
                LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 304),
                LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 305),
                LookupService.getReportFiltersValuesAsOBS(this.context)
                //ReportSaveService.fetchFilterValues(this.context.user.tenantId)
            ]).subscribe(
                // success
                ([_izendaLanguageData, _izendaCompanionData, _reportFileExtensionList, _reportLibraryLov, _finalDispositionLov, _filterValues]) => {
                    _reportFileExtensionList = _reportFileExtensionList.map((o, iDex) => ({ ...o, id: iDex + 1 }));
                    this.setState(
                        {
                            izendaLanguageData: _izendaLanguageData ?? [],
                            izendaCompanionData: _izendaCompanionData,
                            reportFileExtensionList: _reportFileExtensionList,
                            reportLibraryLov: _reportLibraryLov,
                            finalDispositionLov: _finalDispositionLov,
                            filterValues: _filterValues
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );

    }

    getInitialValues() {
        let initObj = {
            isEnglishReport: true,
            isCompanionReport: false,
            reportFileExtensionId: 0,
            reportName: "",
            reportDescription: "",
            reportLibraryId: 0,
            finalDispositionId: 0,
            finalDispositionDate: null,
            [IzendaFilters.IZENDA_DISPLAY_NAME]: this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.IZENDA_DISPLAY_NAME) ?
                this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.IZENDA_DISPLAY_NAME)?.value : null,
            finalDispositionComments: "",

            //Conditionally adding initial values for report date filters
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.START_DATE) && { [IzendaFilters.START_DATE]: new Date(this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.START_DATE).value.split('-')) }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.END_DATE) && { [IzendaFilters.END_DATE]: new Date(this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.END_DATE).value.split('-')) }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.TRANSACTION_START_DATE) && {
                [IzendaFilters.TRANSACTION_START_DATE]: DataService.isStringNullOrEmpty(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.TRANSACTION_START_DATE).value) ? null : new Date(this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.TRANSACTION_START_DATE).value.split('-'))
            }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.TRANSACTION_END_DATE) && {
                [IzendaFilters.TRANSACTION_END_DATE]: DataService.isStringNullOrEmpty(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.TRANSACTION_END_DATE).value) ? null : new Date(this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.TRANSACTION_END_DATE).value.split('-'))
            }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.RECEIVED_START_DATE) && {
                [IzendaFilters.RECEIVED_START_DATE]: DataService.isStringNullOrEmpty(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.RECEIVED_START_DATE).value) ? null : new Date(this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.RECEIVED_START_DATE).value.split('-'))
            }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.RECEIVED_END_DATE) && {
                [IzendaFilters.RECEIVED_END_DATE]: DataService.isStringNullOrEmpty(this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.RECEIVED_END_DATE).value) ? null : new Date(this.state.filterFields.find(filter => filter.sourceFieldName === IzendaFilters.RECEIVED_END_DATE).value.split('-'))
            })
        };

        let arr = this.state.izendaLanguageData;
        for (var i = 0; i < arr.length; i++) {
            const zKey = arr[i].translatedReportLanguage;
            initObj["is" + zKey + "Report"] = arr[i].selected;
        }

        //TODO: need a better approach: pass all these in metadata from srvice

        for (let filter of this.state.filterFields) {
            if (this.showDefaultAllValueFields.includes(filter.sourceFieldName)) {
                let filterValue = this.state.filterValues && this.state.filterValues[this.sourceFieldNameMap.get(filter.sourceFieldName)] ?
                    this.state.filterValues[this.sourceFieldNameMap.get(filter.sourceFieldName)].values[0] : null;
                initObj[filter.sourceFieldName] = [filterValue];
            } else {
                if (filter.operatorSetting === "multiple")//if multi select but doesn't have 'All' as default value
                    initObj[filter.sourceFieldName] = [];
                else if (filter.sourceFieldName === IzendaFilters.CONSOLIDATED_REPORT ||
                    filter.sourceFieldName === IzendaFilters.FEDERAL_PREEMPTION) {
                    initObj[filter.sourceFieldName] = 'N';
                } else if (filter.sourceFieldName === IzendaFilters.CURRENCY) {
                    initObj[filter.sourceFieldName] = 'Euro (EUR)';
                } else if (filter.sourceFieldName === IzendaFilters.RESEARCH_RELATED) {
                    initObj[filter.sourceFieldName] = 'Yes';
                }
            }
        }

        return (initObj);
    }

    validationSchema = Yup.object().shape({});
    getValidationSchema() {
        let filterSchema = Yup.object().shape({
            isEnglishReport: Yup.boolean().required("Required"),
            isCompanionReport: Yup.boolean().required("Required"),
            reportFileExtensionId: this.props.modalAgNode.jurisdiction?.toUpperCase() !== this.US_JURISDICTION ?
                Yup.number().min(1, "Select a Report Type") : Yup.number().nullable(true),

            reportName: Yup.string().required("Enter a Report Name").max(256, "Must be 256 characters or less"),
            reportDescription: Yup.string().max(256, "Must be 256 characters or less"),
            reportLibraryId: Yup.number().min(1, "Select a Report Library"),

            finalDispositionId: Yup.number().min(0, "Select a Final Disposition"),
            finalDispositionDate: Yup.date().nullable(true).min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
            finalDispositionComments: Yup.string().max(256, "Must be 256 characters or less"),

            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.SUBMITTING_ENTITY) && { [IzendaFilters.SUBMITTING_ENTITY]: Yup.string().min(1, "Select a Submitting Entity").required("Select a Submitting Entity") }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.SOURCE) && { [IzendaFilters.SOURCE]: Yup.string().min(1, "Select a Source").required("Select a Source") }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.COMPANY_AFFILIATE) && { [IzendaFilters.COMPANY_AFFILIATE]: Yup.string().min(1, "Select a Company Affiliate").required("Select a Company Affiliate") }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.VENDOR) && { [IzendaFilters.VENDOR]: Yup.string().min(1, "Select a Vendor").required("Select a Vendor") }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.INCLUDED_IN_CONTRACT) && { [IzendaFilters.INCLUDED_IN_CONTRACT]: Yup.string().min(1, "Select a value for Included In Contract").required("Select a value for Included In Contract") }),
            ...(this.state.filterFields.some(filter => filter.sourceFieldName === IzendaFilters.FILENAME) && { [IzendaFilters.FILENAME]: Yup.string().min(1, "Select a File Name").required("Select a File Name") }),
        });

        //This validation is specifically for PI review report date range
        if ([IzendaFilters.RECEIVED_START_DATE, IzendaFilters.RECEIVED_END_DATE, IzendaFilters.TRANSACTION_START_DATE, IzendaFilters.TRANSACTION_END_DATE]
            .every(filter => this.state.filterFields.map(x => x.sourceFieldName).includes(filter)))
            this.validationSchema = filterSchema.concat(this.validateDateRangeSchema());
        else
            this.validationSchema = filterSchema;

        return this.validationSchema;
    }

    validateDateRangeSchema = () => {
        const dateReqMsg = "Enter a transaction date range or a received date range";
        const recErrMsg = "Received end date cannot be before received start date";
        const transErrMsg = "Transaction end date cannot be before Transaction start date";
        const recStDt = IzendaFilters.RECEIVED_START_DATE, recEndDt = IzendaFilters.RECEIVED_END_DATE;
        const trStDt = IzendaFilters.TRANSACTION_START_DATE, trEndDt = IzendaFilters.TRANSACTION_END_DATE;

        let dateDynamicSchema = Yup.object().shape({
            [recStDt]: Yup.date()
                .when([recEndDt, trStDt, trEndDt], (r2, t1, t2, yup) => {
                    if (!(DataService.isValidDate(r2) || DataService.isValidDate(t1) || DataService.isValidDate(t2)))
                        return yup.nullable(true).required(dateReqMsg)
                    else if (DataService.isValidDate(r2)) {
                        var d = r2;
                        d.setHours(0, 0, 0, 0);
                        return yup.nullable(true).required("Required").max(d, recErrMsg)
                    } else
                        return yup.nullable(true)
                }),
            [recEndDt]: Yup.date()
                .when([recStDt, trStDt, trEndDt], (r1, t1, t2, yup) => {
                    if (!(DataService.isValidDate(r1) || DataService.isValidDate(t1) || DataService.isValidDate(t2)))
                        return yup.nullable(true).required(dateReqMsg)
                    else if (DataService.isValidDate(r1)) {
                        var d = r1;
                        d.setHours(0, 0, 0, 0);
                        return yup.nullable(true).required("Required").min(d, recErrMsg)
                    } else
                        return yup.nullable(true)
                }),
            [trStDt]: Yup.date()
                .when([recStDt, recEndDt, trEndDt], (r1, r2, t2, yup) => {
                    if (!(DataService.isValidDate(r1) || DataService.isValidDate(r2) || DataService.isValidDate(t2)))
                        return yup.nullable(true).required(dateReqMsg)
                    else if (DataService.isValidDate(t2)) {
                        var d = t2;
                        d.setHours(0, 0, 0, 0);
                        return yup.nullable(true).required("Required").max(d, transErrMsg)
                    } else
                        return yup.nullable(true)
                }),
            [trEndDt]: Yup.date()
                .when([recStDt, recEndDt, trStDt], (r1, r2, t1, yup) => {
                    if (!(DataService.isValidDate(r1) || DataService.isValidDate(r2) || DataService.isValidDate(t1)))
                        return yup.nullable(true).required(dateReqMsg)
                    else if (DataService.isValidDate(t1)) {
                        var d = t1;
                        d.setHours(0, 0, 0, 0);
                        return yup.nullable(true).required("Required").min(d, transErrMsg)
                    } else
                        return yup.nullable(true)
                }),
        }, [[recStDt, recEndDt], [recStDt, trStDt], [recStDt, trEndDt], [recEndDt, trStDt], [recEndDt, trEndDt], [trStDt, trEndDt]]);
        return dateDynamicSchema;
    }


    handleSubmit = async (_formikProps) => {
        if (!_formikProps.isSubmitting && _formikProps.isValid) {
            await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
                .then((x) => {
                    // 1) set the status
                    this.setState({ fetchResult: ResultStatus.SAVING });
                    let transLatedReportLanguageList = '';
                    var keyArr = Object.keys(_formikProps.values);

                    for (var i = 2; i < keyArr.length; i++) {
                        if (_formikProps.values[keyArr[i]] === true) {
                            let currLang = keyArr[i];
                            let _reportObj = this.state.izendaLanguageData.find(o => "is" + o.translatedReportLanguage + "Report" === currLang);
                            if (_reportObj) {
                                transLatedReportLanguageList += `${_reportObj.translatedReportLanguage},`;
                            }
                        }
                    }
                    transLatedReportLanguageList = transLatedReportLanguageList.replace(/(^,)|(,$)/g, "");

                    const getFileType = () => {
                        if (this.props.modalAgNode.jurisdiction?.toUpperCase() === this.US_JURISDICTION)
                            return "EXCEL";
                        return this.state.reportFileExtensionList.find((o) => o.id === _formikProps.values.reportFileExtensionId)?.fileType;
                    };

                    const getFileTypeExtension = () => {
                        if (this.props.modalAgNode.jurisdiction?.toUpperCase() === this.US_JURISDICTION)
                            return "xls";
                        return this.state.reportFileExtensionList.find((o) => o.id === _formikProps.values.reportFileExtensionId)?.fileTypeExtension;
                    }

                    const getFilterValue = (_formikProps, filter) => {
                        if (Array.isArray(_formikProps.values[filter.sourceFieldName]))
                            return _formikProps.values[filter.sourceFieldName].join(',');
                        return _formikProps.values[filter.sourceFieldName];
                    }

                    //create filter fields payload
                    const filterFieldKeys = Array.from(this.sourceFieldNameMap.keys());
                    let selectedFilterKeys = keyArr.filter(value => filterFieldKeys.includes(value));
                    let selectedFilterFields = selectedFilterKeys.map(f => this.state.filterFields.find(filter => filter.sourceFieldName == f));
                    let filterFields = selectedFilterFields.map(f => ({
                        filterId: f.filterId,
                        id: f.id,
                        operatorId: f.operatorId,
                        alias: f.alias,
                        value: getFilterValue(_formikProps, f),
                        querySourceFieldName: f.querySourceFieldName
                    }));

                    // 2) extract the formik values into an object
                    let mappedObj = {
                        isGemboxReport: this.props.modalAgNode.isGemboxReport,
                        IzendaCurrentTenantId: this.state.izendaCurrentTenantId,
                        reportDetailID: 0,
                        izendaReportID: this.props.modalAgNode.izendaReportId,
                        reportId: this.props.modalAgNode.reportId,
                        fileType: getFileType(),
                        fileTypeExtension: getFileTypeExtension(),
                        reportNameForJsonMapping: this.props.modalAgNode.reportName,
                        reportDetailName: _formikProps.values.reportName,
                        reportDetailDescription: _formikProps.values.reportDescription,
                        reportLibraryId: _formikProps.values.reportLibraryId,
                        reportLibrary: this.state.reportLibraryLov.find(r => r.lovId == _formikProps.values.reportLibraryId)?.lovKey,
                        startDate: new Date().toISOString(),
                        //endDate: _formikProps.values.finalDispositionDate?.toISOString(),
                        isActive: true,
                        reportName: this.props.modalAgNode.izendaDisplayName,
                        countryID: 0,
                        jurisdiction: this.props.modalAgNode.jurisdiction,
                        finalDispositionName: this.state.finalDispositionLov.find((o) => o.lovId === _formikProps.values.finalDispositionId)?.lovKey,
                        reportCreatedDate: new Date().toISOString(),
                        launchDocument: true,
                        porzioGSTReportID: "DEVTK43F440C42",//TODO : need to revisit these
                        workflowID: 0,
                        porzioGSTWorkflowID: "string",
                        isComplianceSave: _formikProps.values.isEnglishReport,
                        isCompanionSave: this.props.category === ReportCategory.COMPLIANCE_TEMPLATES ? _formikProps.values.isCompanionReport : false,
                        languageslist: this.state.izendaLanguageData.map(x => ({
                            selected: _formikProps.values["is" + this.state.izendaLanguageData.find(o => o.translatedReportLanguage == x.translatedReportLanguage).translatedReportLanguage + "Report"],
                            translatedReportLanguage: x.translatedReportLanguage,
                            translatedReportName: x.translatedReportName,
                            izendaReportName: x.izendaReportName,
                            izendaReportId: x.izendaReportId
                        })),
                        transLatedReportLanguageList: transLatedReportLanguageList,
                        genStatus: "InProgress",
                        statusReason: "",
                        izendaReportFilters: {
                            reportFilter: {
                                filterFields: filterFields
                            }
                        },
                        ...(this.props.category === ReportCategory.COMPLIANCE_TEMPLATES && {
                            finalDisposition: _formikProps.values.finalDispositionId,
                            finalDispositionDate: _formikProps.values.finalDispositionDate?.toISOString(),
                            dispositionComments: _formikProps.values.finalDispositionComments,
                            endDate: _formikProps.values.finalDispositionDate?.toISOString(),
                        }),
                    }

                    // 3) determine the action and assign the appropriate props
                    const actionVerb = "POST"
                    const targetUrl = `/Reports/SaveReportToLibrary?tenantId=${this.context.user.tenantId}&accessToken=${this.context.user.sessionToken}&userId=${this.context.user.userId}`;

                    // 4) save to Api and subscribe for the result
                    this.apiSubscriptions.add(ApiService.setOBS(actionVerb, API_ENDPOINT.REPORTS, targetUrl, JSON.stringify(mappedObj))
                        .subscribe((successResult) => {
                            if (successResult) {
                                ToastService.showSuccess("Report(s) are queued for processing. An email will be sent once completed.");
                                this.props.handleClose(this.props.modalAgNode.izendaReportId);
                            } else {
                                console.error("Error: falsey successResult while saving report", successResult);
                                ToastService.showError("Error: Report not Saved");
                                this.setState({ fetchResult: ResultStatus.ERROR });
                            }
                        }, (errors) => {
                            console.error("Error while saving report", errors);
                            this.setState({ fetchResult: ResultStatus.ERROR });
                        }
                        ));
                })
                .catch((errorObj) => {
                    console.log(errorObj.message);
                    if (errorObj.inner) {
                        errorObj.inner.forEach(err => {
                            _formikProps.setFieldError(err.path, err.message);
                        });
                    }
                    //this.setState({ fetchResult: ResultStatus.ERROR });
                    //errorObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
                });
        }
    }

    handleMultiSelectChange = async (_formikProps, _filter, _selectedValues) => {
        let selectedValues = [].concat(..._selectedValues);
        // check for <All> Item
        const itemAll = DataService.getFirstOrDefault(selectedValues.filter((x) => x === "[All]"));
        // if <all> is newly selected, then remove other elements
        if (itemAll && !_formikProps.values[_filter.sourceFieldName].includes(itemAll)) {
            await _formikProps.setFieldValue(_filter.sourceFieldName, [itemAll], true);
            //this.setState({ [_filter.sourceFieldName]: [itemAll] });
        } else {
            // if <all> was originally selected and a new value is selected, then remove <all>
            if (_formikProps.values[_filter.sourceFieldName].includes(itemAll)) {
                selectedValues = selectedValues.filter(f => f != itemAll);
            }
            await _formikProps.setFieldValue(_filter.sourceFieldName, selectedValues, true);
            //this.setState({ [_filter.sourceFieldName]: [selectedValues] });
        }
    }

    getDateLabel = (filter) => {
        if (filter.sourceFieldName === IzendaFilters.START_DATE)
            return "Start Date";
        else if (filter.sourceFieldName === IzendaFilters.END_DATE)
            return "End Date";
        else return filter.alias;
    }

    render() {
        const { classes } = this.props;
        switch (this.state.fetchResult) {
            case ResultStatus.LOADING:
                return (<PageLoadingComponent small classes={classes} label="Loading Report Details" />);
            case ResultStatus.SAVING:
                return (<PageLoadingComponent small classes={classes} label="Saving Report Details" />);
            case ResultStatus.LOADED:
                return (
                    <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()}
                        validationSchemaOptions={{ showMultipleFieldErrors: true }}                        >
                        {(fProps) => (
                            <form>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DialogTitle>
                                        <AppBar position="static">
                                            <Toolbar>
                                                <Typography variant="h6" className={classes.root}>Report Detail: {this.props.modalAgNode.reportName}</Typography>
                                                {LayoutService.getIconButton(false, MatIconService.OK, "Save Reports", () => { this.handleSubmit(fProps) }, "inherit", "keyHandleSubmit")}
                                                {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", this.props.handleClose, "secondary", "keyCancel")}
                                            </Toolbar>
                                        </AppBar>
                                    </DialogTitle>
                                    <DialogContent style={{ maxHeight: `calc(100vh - 160px)` }}>
                                        <DialogContentText id="alert-dialog-description">

                                            <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }}  >
                                                <Grid container spacing={1} style={{ minHeight: "10vh" }}>
                                                    {/* --- Select Reports:---------------------------- */}
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.root}>Select Reports:</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FormGroup style={{ paddingLeft: "4px", paddingTop: "0px" }}>
                                                            {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isEnglishReport", "English Report")}
                                                        </FormGroup>
                                                    </Grid>
                                                    {this.state.izendaLanguageData.map(z => {
                                                        return (
                                                            <Grid item xs={12} sm={12}>
                                                                <FormGroup style={{ paddingLeft: "4px", paddingTop: "0px" }}>
                                                                    {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "is" + z.translatedReportLanguage + "Report", z.translatedReportLanguage + " Report")}
                                                                </FormGroup>
                                                            </Grid>
                                                        );
                                                    })}

                                                    {this.props.category === ReportCategory.COMPLIANCE_TEMPLATES ?
                                                        <Grid item xs={12} sm={12}>
                                                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "0px" }}>
                                                                {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isCompanionReport", "Porzio Analysis Report")}
                                                            </FormGroup>
                                                        </Grid>
                                                        : null}



                                                    {/* --- Select Filters:---------------------------- */}
                                                    <Grid item xs={12}><Typography variant="h6" className={classes.root}>Select Filters:</Typography></Grid>
                                                    {this.state.filterFields.map(filter => {
                                                        if (filter.dataType === "Datetime") {
                                                            return (<Grid item xs={12} sm={6}>
                                                                <DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, filter.sourceFieldName,
                                                                    this.getDateLabel(filter), false)} format="yyyy-MM-dd" style={{ minWidth: "47%" }} />
                                                            </Grid>);
                                                        } else if (filter.sourceFieldName == IzendaFilters.IZENDA_DISPLAY_NAME) {
                                                            return null;
                                                        } else {
                                                            if (this.sourceFieldNameMap.get(filter.sourceFieldName)) {
                                                                if (filter.operatorSetting == 'multiple') {
                                                                    return (<Grid item xs={12} sm={12}>
                                                                        <FormControl disabled={this.state.isReadOnly} style={{ minWidth: "95%" }} className={classes.dialogControl}
                                                                            error={fProps.errors[filter.sourceFieldName] !== undefined && fProps.errors[filter.sourceFieldName] !== null}>
                                                                            <Autocomplete disableListWrap={true}
                                                                                labelid={filter.sourceFieldName}
                                                                                id={filter.sourceFieldName}
                                                                                disabled={this.state.isReadOnly}
                                                                                multiple
                                                                                defaultValue={[fProps.values[filter.sourceFieldName]].flat()}
                                                                                value={[fProps.values[filter.sourceFieldName]].flat()}
                                                                                options={this.state.filterValues[this.sourceFieldNameMap.get(filter.sourceFieldName)].values}
                                                                                noOptionsText={"Not Found"}
                                                                                getOptionLabel={(option) => option}
                                                                                onChange={async (e, values, reason) => {
                                                                                    let newValues = [].concat(...values);
                                                                                    this.handleMultiSelectChange(fProps, filter, newValues);
                                                                                }}
                                                                                renderTags={(value, getTagProps) =>
                                                                                    value.map((option, index) => (
                                                                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                                                    ))}
                                                                                // render in the text in the chip //TODO :not working correctly so using render Tags
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} variant={MatThemeService.inputVariant} label={this.state.filterValues[this.sourceFieldNameMap.get(filter.sourceFieldName)].displayText} />
                                                                                )}
                                                                            />
                                                                            <FormHelperText>{fProps.errors[filter.sourceFieldName]}</FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    );
                                                                }
                                                                return (<Grid item xs={12} sm={12}>
                                                                    <FormControl disabled={this.state.isReadOnly} style={{ minWidth: "95%" }} className={classes.dialogControl}
                                                                        error={
                                                                            fProps.errors[filter.sourceFieldName] !== undefined &&
                                                                            fProps.errors[filter.sourceFieldName] !== null
                                                                        }>
                                                                        <InputLabel id={filter.sourceFieldName}>{this.state.filterValues[this.sourceFieldNameMap.get(filter.sourceFieldName)].displayText}</InputLabel>
                                                                        <Select labelId={filter.sourceFieldName}
                                                                            id={filter.sourceFieldName}
                                                                            value={fProps.values[filter.sourceFieldName]}
                                                                            onChange={(e) => {
                                                                                fProps.setFieldValue(filter.sourceFieldName, e.target.value, true);
                                                                                fProps.setFieldTouched(filter.sourceFieldName, true, false);
                                                                                //this.handleSelectChange(fProps, filter.sourceFieldName, e.target.value);
                                                                            }}
                                                                            style={{ width: "95" }}>
                                                                            {this.state.filterValues[this.sourceFieldNameMap.get(filter.sourceFieldName)] ?
                                                                                this.state.filterValues[this.sourceFieldNameMap.get(filter.sourceFieldName)].values.map((el) => (
                                                                                    <MenuItem key={el} value={el}>{el}</MenuItem>
                                                                                )) : <MenuItem value=""> <em>None</em></MenuItem>}
                                                                        </Select>
                                                                        <FormHelperText>{fProps.errors[filter.sourceFieldName]}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                                );
                                                            }
                                                        }
                                                    })}






                                                    {/* --- Save Report Details:------------------------ */}

                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.root}>Save Report Details:</Typography>
                                                    </Grid>
                                                    {this.props.modalAgNode.jurisdiction?.toUpperCase() !== this.US_JURISDICTION ?
                                                        <Grid item xs={12}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "reportFileExtensionId", "Report *", [{ id: 0, fileType: "Select" }, ...this.state.reportFileExtensionList.filter(x => x.fileTypeExtension != "doc" &&  x.fileTypeExtension != "xml")], "id", "fileType", "95%")}</Grid>
                                                        : null}

                                                    <Grid item xs={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "reportName", "Report Name", true)} style={{ minWidth: "80%" }} rows={3} multiline /></Grid>

                                                    <Grid item xs={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "reportDescription", "Report Description", false)} style={{ minWidth: "80%" }} rows={3} multiline /></Grid>

                                                    <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "reportLibraryId", "Report Library *",
                                                        [{ lovId: 0, lovKey: "Select" }, ...this.state.reportLibraryLov], "lovId", "lovKey", "95%")}
                                                    </Grid>

                                                    {this.props.category === ReportCategory.COMPLIANCE_TEMPLATES ?
                                                        <>
                                                            <Grid item xs={12} sm={6}>
                                                                {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "finalDispositionId", "Final Disposition",
                                                                    [{ lovId: 0, lovKey: "Select" }, ...this.state.finalDispositionLov], "lovId", "lovKey", "95%")}
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "finalDispositionDate", "Final Disposition Date", false)}
                                                                    format="yyyy-MM-dd" style={{ minWidth: "47%" }} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "finalDispositionComments", "Final Disposition Comments", false
                                                                )} style={{ minWidth: "80%" }} rows={3} multiline />
                                                            </Grid>
                                                        </>
                                                        : null}
                                                </Grid>
                                            </Box>

                                        </DialogContentText>
                                    </DialogContent>
                                </MuiPickersUtilsProvider>
                            </form>
                        )}
                    </Formik>
                );
            case ResultStatus.ERROR:
            default:
                return (<PageErrorComponent label={this.state.errorMessage} onClose={this.props.handleClose} classes={classes} onRetry={() => this.fetchFiltersFields()} />);
        }
    }
}
export default LayoutService.getHocComponenet(ReportSaveComponent);